<template>
  <b-row
    class="d-flex align-items-center match-height"
  >
    <b-col>
      <b-form-group
        label="Araç Kartı"
        label-for="id_com_cars"
      >
        <v-select
          id="id_com_cars"
          v-model="service.id_com_cars"
          :options="cars"
          placeholder="Araç Kartı"
          :reduce="car => car.id"
          label="license_plate"
        >
          <template v-slot:option="option">
            <div v-if="option.brand && option.model">
              {{ option.brand }} {{ option.model }}
            </div>
            <div v-else>
              {{ option.swap_brand }} {{ option.swap_model }}
            </div>
            <div>
              <small class="text-danger">{{ option.myear }}</small>
            </div>
            <div>
              <small class="text-warning">{{ option.license_plate }}</small>
            </div>
          </template>
          <template v-slot:selected-option="option">
            <div v-if="option.brand && option.model">
              {{ option.brand }} {{ option.model }} <span class="text-primary">{{ option.license_plate }}</span>
            </div>
            <div v-else>
              {{ option.swap_brand }} {{ option.swap_model }} <span class="text-primary">{{ option.license_plate }}</span>
            </div>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col
      v-if="service.id_com_cars"
      cols="12"
      md="auto"
    >
      <b-form-checkbox
        v-model="carDetail"
        name="check-button"
        switch
        inline
      >
        Detay
      </b-form-checkbox>
    </b-col>
    <b-col
      cols="12"
      md="auto"
    >
      <b-button
        v-if="!service.id_com_cars"
        v-b-toggle.sidebar-invoice-add-new-car
        variant="danger"
        type="submit"
      >
        <FeatherIcon icon="PlusIcon" />
        Araç Kartı Ekle
      </b-button>
      <b-button
        v-else
        v-b-toggle.sidebar-invoice-add-new-car
        variant="primary"
        :disabled="!carDetail"
      >
        Bilgileri Güncelle
      </b-button>
    </b-col>
    <b-col
      v-if="carDetail"
      cols="12"
    >
      <car-card :id_com_cars="service.id_com_cars" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormCheckbox,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import CarCard from '@/views/Service/components/CarCard.vue'

export default {
  name: 'CustomerCars',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormCheckbox,
    vSelect,
    CarCard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      carDetail: false,
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    cars() {
      return this.$store.getters['cars/getCars']
    },
  },
}
</script>
